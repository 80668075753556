<template>
    <div class="inline-flex items-center mr-2 text-xs text-gray-300">
      <span v-show="timelineSaveLastSuccess && showSuccessRelative">
        Saved just now
      </span>
      <span v-show="timelineSaveInProgress">
        Saving <fa-icon icon="spinner" spin class="ml-1 text-green-500" />
      </span>
    </div>
  </template>
  <script>
  export default {
    props: {
      asset: Object,
      timelineSaveTimeout: Number,
      timelineSaveInProgress: Boolean,
      timelineSaveLastSuccess: {
        type: [Number, null]
      }
    },
  
    data() {
      return {
        localTimelineTickSpeed: 1000, // 1 sec.
        localTimelineTimeout: null,
        localTimestamp: null,
        relativeSaveTimeVisibilityPeriod: 5000 // 5 seconds
      }
    },
  
    watch: {
      timelineSaveLastSuccess: {
        handler(val) {
          if (val !== null) this.startTimelineTimeout()
        },
        immediate: true
      },
      showSuccessRelative(val) {
        if (val === false) {
          // Stop our ticker
          clearInterval(this.localTimelineTimeout)
        }
      }
    },
  
    methods: {
      startTimelineTimeout() {
        this.localTimestamp = Date.now()
        clearInterval(this.localTimelineTimeout)
        this.localTimelineTimeout = setInterval(() => {
          this.localTimestamp = Date.now()
        }, this.localTimelineTickSpeed)
      }
    },
  
    computed: {
      showSuccessRelative() {
        if (!this.relativeSaveTime) return false
        if (this.relativeSaveTime < 0) return false
        if (this.relativeSaveTime > this.relativeSaveTimeVisibilityPeriod)
          return false
  
        return true
      },
  
      relativeSaveTime() {
        if (this.timelineSaveLastSuccess === null || this.localTimestamp === null)
          return false
  
        return this.localTimestamp - this.timelineSaveLastSuccess
      }
    }
  }
  </script>
  